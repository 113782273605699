<template>
  <div
    class="modal"
    @click="closeModal"
  >
    <b-form class="modal-content">
      <b-form-group>
        <h1 style="font-size: 30px; font-weight: 500; color: red;">
          {{ $t('Warning') }}
        </h1>
        <p>{{ $t('DayDifference') }}</p>
        <b-button
          style="background: transparent linear-gradient(53deg, #0071AE 0%, #55B94A 100%) 0% 0% no-repeat padding-box; border: transparent;"
          @click="closeModal"
        >
          {{ $t('Close') }}
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 30%;
  text-align: left;
}

button {
  margin-top: 10px;
  cursor: pointer;
}
</style>
