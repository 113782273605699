<template>
  <div class="home">
    <!-- <Navbar />
    <Sidebar /> -->
    <section
      class="main product__management"
      style="display: block;"
    >
      <DailyOverviewListingVue /></section>

  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import { mapGetters } from 'vuex';
import DailyOverviewListingVue from '@/components/DailyOverviewListing.vue';


export default {
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    DailyOverviewListingVue,
    // StoreFiliale,

  },
  data() {
    return {
      filialeIdShow: '',
      routeIdShow: '',
      truckShow: {},
      time2: null,
    };
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    // inside(item) {
    //   this.truck = item;
    // },
  },
  mounted() {
  },
  methods: {
    time1(value) {
      // console.log('time1', value)
      this.time2 = value
    },
    truckId(item) {
      this.truckShow = item;
    },
    dressId(id) {
      this.filialeIdShow = id;
      this.routeIdShow = ''
      this.truckShow = {}
    },
    routeId(id) {
      this.routeIdShow = id;
      this.truckShow = {}
    },
  },
};
</script>

<style lang="scss" scoped>
.product__management .hide-on-products {
  display: none;
}

.main{
  padding: 90px 15px 15px 110px;
}

@media screen and (max-width: 1200px){
  .main{
    padding: 90px 15px 15px 80px;
  }
}

@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
  }
}
@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
</style>
