<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      card
    >
      <b-tab
        :title="$t('BudgetPreperation')"
        active
      >
        <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
          {{ $t("BudgetPreperation") }}
        </p> -->
        <template #title>
          <strong style="color: #0071AE">{{ $t("BudgetPreperation") }}</strong>
        </template>
        <div
          class="all"
          style="margin: -10px 10px 0px 10px; display: flex;
          justify-content: space-between; align-items: center;justify-content: space-between;"
        >
          <button
            v-b-modal.excel-tasks
            class="button"
            style="border-radius: 6px; height: 36px; margin-top: 25px; margin-left: 0px; width:220px"
          >
            <b-icon-plus />
            {{ $t('UploadCSV') }}
          </button>
          <div
            class="inputs"
            style="display: flex;
            margin-bottom: 15px; align-items: end;"
          >
            <div style="margin-right: 15px;">
              <b-button
                id="today"
                class="buttonTime"
                type="button"
                variant="none"
                @click="
                  daily()
                "
              >
                {{ $t("Daily") }}
              </b-button>
              <b-button
                id="week"
                class="buttonTime"
                type="button"
                variant="none"
                @click="
                  week()
                "
              >
                {{ $t("Week") }}
              </b-button>
            </div>
            <b-form-group
              id="input-group-9 date"
              :label="$t('StartingDate')"
              label-for="input-9"
              class=""
              style="margin-right: 3px"
            >
              <date-picker
                v-model="time1"
                type="date"
                value-type="format"
                :placeholder="$t('SelectDate')"
                :format="'DD-MM-YYYY'"
                :start-day="1"
                :lang="lang"
              />
            </b-form-group>
            <b-form-group
              id="input-group-9 date"
              :label="$t('EndingDate')"
              label-for="input-9"
              class=""
            >
              <date-picker
                v-model="time2"
                type="date"
                value-type="format"
                :placeholder="$t('SelectDate')"
                :format="'DD-MM-YYYY'"
                :start-day="1"
                :lang="lang"
              />
            </b-form-group>
            <!-- <b-button
          type="button"
          variant="none"
          style="margin-left: 5px;"
          class="buttonSubmit searchButton"
          :disabled="time1== null || time2== null"
          @click="weeklyReport()"
        >
          {{ $t("Search") }}
        </b-button> -->
          </div>
        </div>
        <div
          class="container tt"
          :style="pressedWeek == false? 'width: 100%; display: flex; flex-wrap: wrap;justify-content: center; max-width: 100%;width: 100%; margin-top: -3px; height: 100%; overflow-y: scroll': ''"
        >


          <!-- v-if="!pressedWeek" -->
          <table
            v-if="!pressedWeek"

            responsive
            class="team_table"
            style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%); margin-left: 0%; margin-bottom: 20px; background-color: white;"
          >
            <div
              v-for="item in getRouteMetricsReportingForStore"
              :key="item.id"
            >
              <p style="text-align: center; margin: 10px 0; font-weight: bold; font-size: 15px;">
                {{ dateModified(item.date) }} -  {{ dateModi(item.date) }}
              </p>
              <table

                responsive
                class="team_table"
                style="width: 100%; margin-left: 0.5%;"
              >
                <thead>
                  <tr style="text-align: center;">
                    <th
                      style="padding: 10px;border-radius: 10px 0 0 0;"
                    >
                      {{ $t("Filiale") }}
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      {{ $t("No.Employee") }}
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      {{ $t("EstimatedEarnings") }}
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      {{ $t("CurrentEarnings") }}
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      +/- CHF
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      {{ $t("DayClosed") }}
                    </th>
                    <th
                      style="padding: 10px; text-align: center;"
                    >
                      {{ $t("ServiceTime") }}
                    </th>
                    <th
                      style="padding: 10px; border-radius: 0 10px 0 0; text-align: center;"
                    >
                      {{ $t("RoutesCreated") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="manager in item.dailyReporting">
                    <tr
                      :key="manager.managerId"
                      style="height: 34px;
                  font-size: 14px;"
                    >
                      <td>{{ manager.storeName }}</td>
                      <td
                        style="display: flex; justify-content: center;"
                      >
                        <b-form-spinbutton
                          v-model="manager.numberOfEmployees"
                          min="0"
                          max="1000"
                          @change="handleNumberOfEmployeesChangee(manager.routeMetricId,manager.numberOfEmployees )"
                        /></td>
                      <td style="text-align: center;">
                        <p>
                          {{
                            isSaturdayy(item.date)
                              ? (manager.numberOfEmployees * 600).toFixed(2) + 'CHF'
                              : (manager.numberOfEmployees * 600).toFixed(2) + 'CHF'
                          }}
                        </p>
                      </td>
                      <td style="text-align: center;">
                        {{ manager.numberOfRoutesAmounts.toFixed(2) }}CHF
                      </td>
                      <!-- :style="manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600) > 0 ? 'color: green':'color: red'" -->
                      <!-- :style="{'color': isSaturdayy(item.date) ? 'green' : manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600) > 0 ? 'green' : 'red'}" -->
                      <td
                        :style="{'color': manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600) > 0 ? 'green' : isSaturdayy(item.date) && (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600)) > 0 ? 'green': 'red' }"

                        style="text-align: center;"
                      >
                        {{
                          isSaturdayy(item.date)
                            ? (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600)).toFixed(2) + 'CHF'
                            : (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600)).toFixed(2) + 'CHF'
                        }}
                      </td>
                      <td
                        style="z-index: 0; text-align: center;"
                      >
                        <label class="switch">
                          <input
                            v-model="manager.completed"
                            type="checkbox"
                            @input="changeComplete(manager.routeMetricId, manager.completed)"
                          >
                          <span class="slider round" />
                        </label>
                      </td>
                      <td
                        style="z-index: 0; text-align: center;"
                      >
                        <label class="switch">
                          <input
                            v-model="manager.serviceTime"
                            type="checkbox"
                            @input="changeServiceTime(manager.routeMetricId, manager.serviceTime)"
                          >
                          <span class="slider round" />
                        </label>
                      </td>
                      <td
                        style="z-index: 0; text-align: center;"
                      >
                        <label class="switch">
                          <input
                            v-model="manager.routeCreated"
                            type="checkbox"
                            @input="changeRouteCreated(manager.routeMetricId, manager.routeCreated)"
                          >
                          <span class="slider round" />
                        </label>
                      </td>
                    </tr>
                  </template>
                  <td />
                  <td />
                  <tr style="height: 34px; font-size: 14px; background-color: #2da3e3;">
                    <td style="color: white">
                      <b>{{ $t('Total') }}</b>
                    </td>
                    <td
                      style="text-align: center; color: white;"
                    >
                      <b>{{ totalNumberOfEmployees }}</b>
                    </td>
                    <td
                      style="text-align: center;color: white"
                      :style="totalEstimatedEarnings > 0 ? 'color: green':'color: red'"
                    >
                      <b>{{ totalEstimatedEarnings.toFixed(2) }}CHF</b>
                    </td>
                    <td
                      style="text-align: center;color: white"
                      :style="totalCurrentEarnings > 0 ? 'color: green':'color: red'"
                    >
                      <b>{{ totalCurrentEarnings.toFixed(2) }}CHF</b>
                    </td>
                    <!-- :style="(totalCurrentEarnings - (totalNumberOfEmployees * 600)) > 0 ? 'color: green':'color: red'" -->
                    <td
                      style="text-align: center;color: white"
                      :style="{'color': (totalCurrentEarnings - (totalNumberOfEmployees * 600)) > 0 ? 'green' : isSaturdayy(item.date) && (totalCurrentEarnings - (totalNumberOfEmployees * 600)) > 0 ? 'green': 'red' }"
                    >
                      <b>
                        {{
                          isSaturdayy(item.date)
                            ? (totalCurrentEarnings - (totalNumberOfEmployees * 600)).toFixed(2) + 'CHF'
                            : (totalCurrentEarnings - (totalNumberOfEmployees * 600)).toFixed(2) + 'CHF'
                        }}
                      </b>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>

                </tbody>
              </table>
            </div>
          </table>
        </div>

        <div
          v-if="pressedWeek"
          style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%);
    flex-wrap: wrap;
    justify-content: center; height: 100%; overflow-y: scroll;
    "
        >
          <div
            v-for="item in getRouteMetricsReportingForStoreSecond"
            :key="item.id"
          >
            <p style="text-align: center; margin: 10px 0; font-weight: bold;">
              {{ dateModified(item.date) }} -  {{ dateModi(item.date) }}
            </p>
            <table

              responsive
              class="team_table"
              style="width: 90%; margin-left: 4%; text-align: left; height: 150px; overflow: scroll"
            >
              <thead>
                <tr>
                  <th
                    style="padding: 10px; border-radius: 10px 0 0 0; text-align: left;"
                  >
                    {{ $t("Filiale") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("No.Employee") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("EstimatedEarnings") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("CurrentEarnings") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    +/- CHF
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("DayClosed") }}
                  </th>
                  <th
                    style="padding: 10px; text-align: left;"
                  >
                    {{ $t("ServiceTime") }}
                  </th>
                  <th
                    style="padding: 10px; border-radius: 0 10px 0 0; text-align: left;"
                  >
                    {{ $t("RoutesCreated") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="manager in item.dailyReporting">
                  <tr
                    :key="manager.managerId"
                    style="height: 34px;
                font-size: 14px;"
                  >
                    <td>{{ manager.storeName }}</td>
                    <td style="display: flex; justify-content: center;">
                      <b-form-spinbutton
                        v-model="manager.numberOfEmployees"
                        min="0"
                        max="1000"
                        @change="handleNumberOfEmployeesChangee(manager.routeMetricId,manager.numberOfEmployees )"
                      /></td>
                    <td style="text-align: left;">
                      <p>
                        {{
                          isSaturdayy(item.date)
                            ? (manager.numberOfEmployees * 600).toFixed(2) + 'CHF'
                            : (manager.numberOfEmployees * 600).toFixed(2) + 'CHF'
                        }}
                      </p>
                    </td>
                    <td style="text-align: left;">
                      {{ manager.numberOfRoutesAmounts.toFixed(2) }}CHF
                    </td>
                    <!-- :style="manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600) > 0 ? 'color: green':'color: red'" -->
                    <td
                      :style="{'color': manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600) > 0 ? 'green' : isSaturdayy(item.date) && (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600))> 0 ? 'green': 'red' }"

                      style="text-align: left;"
                    >
                      {{
                        isSaturdayy(item.date)
                          ? (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600)).toFixed(2) + 'CHF'
                          : (manager.numberOfRoutesAmounts - (manager.numberOfEmployees * 600)).toFixed(2) + 'CHF'
                      }}
                    </td>
                    <td
                      style="z-index: 0; text-align: left;"
                    >
                      <label class="switch">
                        <input
                          v-model="manager.completed"
                          type="checkbox"
                          @input="changeComplete(manager.routeMetricId, manager.completed)"
                        >
                        <span class="slider round" />
                      </label>
                    </td>
                    <td
                      style="z-index: 0; text-align: left;"
                    >
                      <label class="switch">
                        <input
                          v-model="manager.serviceTime"
                          type="checkbox"
                          @input="changeServiceTime(manager.routeMetricId, manager.serviceTime)"
                        >
                        <span class="slider round" />
                      </label>
                    </td>
                    <td
                      style="z-index: 0; text-align: left;"
                    >
                      <label class="switch">
                        <input
                          v-model="manager.routeCreated"
                          type="checkbox"
                          @input="changeRouteCreated(manager.routeMetricId, manager.routeCreated)"
                        >
                        <span class="slider round" />
                      </label>
                    </td>
                  </tr>
                </template>
                <td />
                <td />
                <tr style="height: 34px; font-size: 14px; background-color: #2da3e3;">
                  <td style="padding: 10px; color: white;">
                    <b>{{ $t('Total') }}</b>
                  </td>
                  <td style="padding: 10px; text-align: center; color: white;">
                    <b>{{ getDailyTotal(item.date).totalEmployees }}</b>
                  </td>
                  <td
                    style="padding: 10px; text-align: left; color: white;"
                    :style="getDailyTotal(item.date).totalEstimatedEarnings > 0 ? 'color: green':'color: red'"
                  >
                    <b>{{ getDailyTotal(item.date).totalEstimatedEarnings.toFixed(2) }}CHF</b>
                  </td>
                  <td
                    style="padding: 10px; text-align: left; color: white;"
                    :style=" getDailyTotal(item.date).totalCurrentEarnings > 0 ? 'color: green':'color: red'"
                  >
                    <b>{{ getDailyTotal(item.date).totalCurrentEarnings.toFixed(2) }}CHF</b>
                  </td>
                  <!-- :style=" (getDailyTotal(item.date).totalCurrentEarnings - (getDailyTotal(item.date).totalEmployees * 600)) > 0 ? 'color: green':'color: red'" -->
                  <td
                    style="padding: 10px; text-align: left; color: white;"
                    :style="{'color': (getDailyTotal(item.date).totalCurrentEarnings - (getDailyTotal(item.date).totalEmployees * 600)) > 0 ? 'green' : isSaturdayy(item.date) && (getDailyTotal(item.date).totalCurrentEarnings - (getDailyTotal(item.date).totalEmployees * 600)) > 0 ? 'green': 'red' }"
                  >
                    <b>
                      {{
                        isSaturdayy(item.date)
                          ? (getDailyTotal(item.date).totalCurrentEarnings - (getDailyTotal(item.date).totalEmployees * 600)).toFixed(2)+ 'CHF'
                          : (getDailyTotal(item.date).totalCurrentEarnings - (getDailyTotal(item.date).totalEmployees * 600)).toFixed(2) + 'CHF'
                      }}
                    </b>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </b-tab>
      <b-tab
        :title="$t('BudgetPreperation')"
      >
        <template #title>
          <strong style="color: #0071AE">{{ $t("Chart") }}</strong>
        </template>
        <div class=" overview">
          <div
            style="    display: flex;
            justify-content: center;
            /* width: 40%; */
            margin-bottom: 10px;
            margin: 1% 40%;"
          >
            <b-form-group
              id="input-group-8"
              :label="$t('EditY')"
              style="width:100%;  margin-right: 10px;"
              label-for="input-25"
            >
              <b-form-select
                id="input-5"
                v-model="yearDashboard"
                style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                :clearable="false"
                required
                :options="yearsArray"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
            <b-form-group
              id="input-group-8"
              :label="$t('Month')"
              style="width:100%;  margin-right: 10px;"
              label-for="input-25"
            >
              <b-form-select
                id="input-5"
                v-model="monthTest"
                required
                style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                :options="selectMonthType"
                :clearable="false"
                :value="name"
                label="text"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
          <BarChart
            :options="reportsDashboard"
            :tab-index="tabIndex"
            :css-classes="'container-bar-chart'"
            :height="100"
          />
        </div>
      </b-tab>
    </b-tabs>
    <Swal />
    <EndDateModal
      v-if="showModal"
      @closeModal="showModal = false"
    />
    <ExcelTasksModal
      :week="pressedWeek"
      :start="time1"
      :end="time2"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
// import AddRouteModal from '@/components/routes/modals/AddRouteModal.vue';
// import EditRouteModal from '@/components/routes/modals/EditRouteModal.vue';
import ExcelTasksModal from '@/components/ExcelTasksModal.vue';
import DatePicker from 'vue2-datepicker';
import Swal from 'sweetalert2';
// import VueFlatpickr from 'vue-flatpickr-component';
import 'vue2-datepicker/index.css';
import moment from 'moment-timezone'
import connection from '../microsoft-signalR/signalR';
import EndDateModal from './EndDateModal.vue'
import BarChart from './Charts/barChart.vue';
// import Spinner from './Spinner/Spinner.vue'

export default {
  components: {
    DatePicker,
    ExcelTasksModal,
    EndDateModal,
    Swal,
    BarChart,
    // VueFlatpickr,
  },
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      tabIndex: 0,
      showModal: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      form: null,
      initialId: '',
      pageSize: 15,
      buttonClicked: false,
      monthDashboard: null,
      monthTest: moment().format('M'),
      yearDashboard: moment().year(),
      yearsArray: [],

      monthh: null,
      // months: ['January', 'February', 'March', 'April', 'May', 'June',
      //   'July', 'August', 'September', 'October', 'November', 'December'],
      months: [
        {
          value: 0,
          name: 'January',
        },
        {
          value: 1,
          name: 'February',
        },
        {
          value: 2,
          name: 'March',
        },
        {
          value: 3,
          name: 'April',
        },
        {
          value: 4,
          name: 'May',
        },
        {
          value: 5,
          name: 'June',
        },
        {
          value: 6,
          name: 'July',
        },
        {
          value: 7,
          name: 'August',
        },
        {
          value: 8,
          name: 'September',
        },
        {
          value: 9,
          name: 'October',
        },
        {
          value: 10,
          name: 'November',
        },
        {
          value: 11,
          name: 'December',
        },
      ],
      pageNumber: 1,
      loading: false,
      test: [],
      storeGLN: null,
      requestData: null,
      status: true,
      totalEmployees: 0,
      totalPotencial: 0,
      isSaturday: false,
      isSaturday2: null,
      startDate: null,
      endDate: null,
      toggleValue: false,
      totalRouteAmounts: 0,
      totalTrucks: 0,
      totalDifference: 0,
      employee: [],
      potencial: [],
      routeAmount: [],
      trucks: [],
      difference: [],
      pressedWeek: false,
      editFilialeName: '',
      time1: null,
      time2: null,
      store: 'ALL',
      route: {
        tourId: '',
        name: '',
        storeGLN: '',
        startDate: '',
        endDate: '',
        amount: '',
      },
      pageP: 1,
      pageC: 1,
      classNameCh: '',
      selectedDate: moment().format('DD-MM-YYYY'),
      // selectedDate: '',
      searchTerm: '',
      rows: [],
      materialObject: {},
      editMat: {},
    };
  },

  computed: {
    ...mapGetters(['getMonthlyReportingForStoreByBudget', 'getOrdersGrouppedByState',
      'getLoggedInUser',
      'getIsLoading',
      'getStoreMainboardOverview',
      'getFiliales',
      'getLoggedInUser',
      'getDailyReport',
      'getDailyTaskReportingForStore',
      'getWeekTaskReportingForStore',
      'getRouteMetrics',
      'getLanguage',
      'getRouteMetricsReportingForStore',
      'getRouteMetricsReportingForStoreSecond',
      'getWeeklyReport', 'getRoutesWithPagination', 'getRoutesWithPagination', 'getTotalItemsForRoutes', 'getFiliales', 'getTotalItemsForFiliales', 'getFilialesForRoutes',
    ]),
    reportsDashboard() {
      const monthlyReports = this.getMonthlyReportingForStoreByBudget;
      const sortedReports = monthlyReports.sort((a, b) => { return a.storeName.localeCompare(b.storeName) });
      return sortedReports;
    },
    selectMonthType() {
      const selectType = [
        {
          value: 1,
          name: 'January',
        },
        {
          value: 2,
          name: 'February',
        },
        {
          value: 3,
          name: 'March',
        },
        {
          value: 4,
          name: 'April',
        },
        {
          value: 5,
          name: 'May',
        },
        {
          value: 6,
          name: 'June',
        },
        {
          value: 7,
          name: 'July',
        },
        {
          value: 8,
          name: 'August',
        },
        {
          value: 9,
          name: 'September',
        },
        {
          value: 10,
          name: 'October',
        },
        {
          value: 11,
          name: 'November',
        },
        {
          value: 12,
          name: 'December',
        },
      ];

      const transformedOptions = selectType.map((option) => {
        return {
          value: option.value,
          text: option.name,
        };
      });

      return [...transformedOptions];
    },
    dateDifference() {
      const start = moment(this.startDate);
      const end = moment(this.endDate);
      return end.diff(start, 'days');
    },
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = moment(this.time1, 'DD-MM-YYYY').toDate();
      const end = moment(this.time2, 'DD-MM-YYYY').toDate();
      return start <= end;
    },
    totalNumberOfEmployees() {
      return this.getRouteMetricsReportingForStore.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfEmployees }, 0) },
        0,
      );
    },
    totalEstimatedEarnings() {
      return this.getRouteMetricsReportingForStore.reduce(
        (total, item) => {
          return total + item.dailyReporting.reduce((acc, manager) => {
            return this.isSaturdayy(item.date) ? acc + manager.numberOfEmployees * 600 : acc + manager.numberOfEmployees * 600
          }, 0)
        },
        0,
      );
    },
    // Calculate total current earnings
    totalCurrentEarnings() {
      return this.getRouteMetricsReportingForStore.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfRoutesAmounts }, 0) },
        0,
      );
    },
    totalDifferencee() {
      return this.getRouteMetricsReportingForStore.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.difference }, 0) },
        0,
      );
    },
    totalNumberOfEmployees2() {
      return this.getRouteMetricsReportingForStoreSecond.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfEmployees }, 0) },
        0,
      );
    },
    // Calculate total estimated earnings
    totalEstimatedEarnings2() {
      return this.getRouteMetricsReportingForStoreSecond.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfEmployees * 600 }, 0) },
        0,
      );
    },
    // Calculate total current earnings
    totalCurrentEarnings2() {
      return this.getRouteMetricsReportingForStoreSecond.reduce(
        (total, item) => { return total + item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfRoutesAmounts }, 0) },
        0,
      );
    },
    // Calculate total difference
    totalDifferencee2() {
      return this.getRouteMetricsReportingForStoreSecond.reduce(
        (total, item) => {
          return total
          + item.dailyReporting.reduce(
            (acc, manager) => { return acc + manager.numberOfRoutesAmounts - manager.numberOfEmployees * 600 },
            0,
          )
        },
        0,
      );
    },
    dailyTotals() {
      return this.getRouteMetricsReportingForStoreSecond.reduce((totals, item) => {
        const totalEmployees = item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfEmployees }, 0);
        const totalEstimatedEarnings = item.dailyReporting.reduce((acc, manager) => { return this.isSaturdayy(item.date) ? acc + manager.numberOfEmployees * 600 : acc + manager.numberOfEmployees * 600 }, 0);
        const totalCurrentEarnings = item.dailyReporting.reduce((acc, manager) => { return acc + manager.numberOfRoutesAmounts }, 0);
        const totalDifference = item.dailyReporting.reduce((acc, manager) => { return this.isSaturdayy(item.date) ? acc + manager.numberOfRoutesAmounts - manager.numberOfEmployees * 600 : acc + manager.numberOfRoutesAmounts - manager.numberOfEmployees * 600 }, 0);

        totals[item.date] = {
          totalEmployees,
          totalEstimatedEarnings,
          totalCurrentEarnings,
          totalDifference,
        };

        return totals;
      }, {});
    },

  },
  watch: {
    // eslint-disable-next-line func-names
    pageNumber(value) {
      this.loadRouteByPagination({
        storeGLN: null,
        startShift: this.selectedDate,
        endShift: null,
        tourName: null,
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    tabIndex(value) {
      if (value == 1) {
        const year = moment().format('YYYY');
        const month = moment().month() + 1;
        console.log('temp', month, year)
        this.dailyReportingForStoreByBudget({
          month,
          year,
        });
      }
    },
    yearDashboard(value) {
      if (typeof this.monthTest === 'string') {
        // const res = moment(this.monthTest, 'MMMM').format('M');
        this.dailyReportingForStoreByBudget({
          month: this.monthTest,
          year: value,
        });
      } else {
        // const res = moment(this.monthTest.name, 'MMMM').format('M');
        this.dailyReportingForStoreByBudget({
          month: this.monthTest,
          year: value,
        });
      }
    },
    monthTest(value) {
      this.dailyReportingForStoreByBudget({
        month: value,
        year: this.yearDashboard,
      });
    },
    form: {
      deep: true, // Watch for nested changes
      handler(newForm, oldForm) {
        // Check if both newForm and oldForm are defined and have the same length
        if (newForm && oldForm && newForm.length === oldForm.length) {
          // Loop through the managers in the form array
          for (let index = 0; index < newForm.length; index++) {
            const manager = newForm[index];
            const oldManager = oldForm[index];

            // Check if manager and oldManager are defined and numberOfEmployees has changed
            if (
              manager
          && oldManager
          && manager.numberOfEmployees !== oldManager.numberOfEmployees
            ) {
              // Perform the request here using axios (replace URL with your actual endpoint)
              // this.update_employee_number({

              // })
            }
          }
        }
      },
    },

    time1(value) {
      // const res = moment.tz.guess();
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');

      // Check if selected date is a weekend day
      // if (moment(value2).isoWeekday() === 6) { // Saturday
      //   value2.add(2, 'days'); // Move to next Monday
      // } else if (moment(value2).isoWeekday() === 7) { // Sunday
      //   value2.add(1, 'days'); // Move to next Monday
      // }


      this.startDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      // this.startDate = this.startDate.replace(/\+/, '%2B');
      this.route_metrics_reportingForStoreSecond({
        startDate: this.startDate,
        endDate: this.startDate,
      })
      if (!this.buttonClicked) {
        this.time2 = value
      }
    },
    time2(value) {
      this.pressedWeek = true
      const res = moment.tz.guess();
      // const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss').set({ hour: 23, minute: 55 });
      const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss');

      // const value2 = moment.utc(value, 'DD-MM-yyyyTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      // // Check if selected date is a weekend day
      // if (moment(value2).isoWeekday() === 6) { // Saturday
      //   value2.add(2, 'days'); // Move to next Monday
      // } else if (moment(value2).isoWeekday() === 7) { // Sunday
      //   value2.add(1, 'days'); // Move to next Monday
      // }

      this.endDate = moment.tz(value2, 'DD-MM-yyyyTHH:mm:ss', res).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      // this.endDate = this.endDate.replace(/\+/, '%2B');
      const dateDiff = this.dateDifference;
      if (dateDiff > 10) {
        this.showModal = true;
        this.time2 = this.time1;
      } else {
        this.route_metrics_reportingForStoreSecond({
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
    getRouteMetricsReportingForStore(value) {
      this.form = value
    },
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
      }
    },
    // getRouteMetricsReportingForStoreSecond(value) {
    //   for (let i = 0; i < value.length; i++) {
    //     const dateData = value[i].date; // Get the object for a specific date
    //     console.log('tt', dateData)
    //     const date = moment(dateData, moment.ISO_8601, true);
    //     if (date.day() === 3) {
    //       console.log('yes', date)
    //       this.isSaturday2 = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    //     }
    //   }
    // },
  },
  created() {
    this.connectToSignalR();
  },
  mounted() {
    this.fetch();

    const currentYear = new Date().getFullYear();

    for (let i = 0; i <= 5; i++) {
      this.yearsArray.push(currentYear - i);
    }

    // Set the selected year to the current year
    this.selectedYear = currentYear;

    connection.on('sendBudgetNotificationAlert', (arg) => {
      console.log('SendBugetNotificationAlert received:', arg);

      if (!this.pressedWeek) {
        this.route_metrics_reportingForStore({
          startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        })
      } else {
        const temp1 = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT00:05:ss.SSS[Z]')
        const temp2 = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT23:59:ss.SSS[Z]')
        try {
        // this.changeLoadingtoTrue(false);
          this.route_metrics_reportingForStoreSecond({
            startDate: temp1,
            endDate: temp2,
          }).then(() => {
          // this.changeLoadingtoTrue(true);
          });
        } catch (e) {
          console.log(e);
        }
      }

      Swal.fire({
        icon: 'success',
        title: this.$t('Notification'),
        text: this.$t('notificationText'),
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      // this.$notify({
      //   group: 'foo',
      //   title: this.$t('Notification'),
      //   text: this.$t('notificationText'),
      //   duration: 5000,
      //   speed: 600,
      //   width: 400,
      //   reverse: false,
      // });
      this.notificationLink = '/daily-overview';
      console.log('Navigated to /');
    });
    function fulfilled() {
    // do something on start
      console.log('Connection to User Hub Successful');
    }
    function rejected() {
      console.log('Connection to User Hub Failed');
    }
    connection.start().then(fulfilled, rejected);
  },

  methods: {
    ...mapActions([
      'addTours',
      'loadFilialePagination',
      'loadRouteByPagination',
      'loadFilialePaginationForRoutes',
      'editRoute',
      'loadDailyReportForStore', 'changeLoadingtoTrue',
      'resetOrdersOverview',
      'loadFilialePagination',
      'loadStoreMainboardOverview',
      'loadUpComingVacations',
      'loadDailyReportForStore',
      'loadDailyTaskReportingForStore',
      'loadWeeklyTaskReportingForStore',
      'route_metrics_reportingForStore',
      'update_employee_number',
      'route_metrics_reportingForStore',
      'update_completed',
      'changeServiceTime',
      'changeRouteCreated',
      'update_service_time',
      'update_route_created',
      'route_metrics_reportingForStoreSecond',
      'dailyReportingForStoreByBudget',
    ]),
    handleNumberOfEmployeesChangee(val1, val2) {
      this.update_employee_number({
        routeMetricId: val1,
        newNumber: val2,
      })
    },
    connectToSignalR() {
      function fulfilled() {
        // do something on start
        console.log('Connection to User Hub Successful');
      }
      function rejected() {
        console.log('Connection to User Hub Failed');
        // Retry the connection after a delay
        // setTimeout(() => {
        // }, 5000); // 3 seconds delay before retrying
      }

      connection.start().then(fulfilled, rejected);
    },
    disconnectFromSignalR() {
      connection.stop();
    },

    isSaturdayy(dateString) {
      const date = new Date(dateString);
      return date.getDay() === 6; // Saturday is represented by 6 in JavaScript
    },

    customDatePickerOptions() {
      return {
        startingDay: 1, // 1 corresponds to Monday (0 is Sunday)
        firstDayOfWeek: 1,
        // Other options...
      };
    },

    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);


        await this.route_metrics_reportingForStore({
          startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    getDailyTotal(date) {
      return this.dailyTotals[date] || {
        totalEmployees: 0,
        totalEstimatedEarnings: 0,
        totalCurrentEarnings: 0,
        totalDifference: 0,
      };
    },
    // eslint-disable-next-line consistent-return
    dateModi(value) {
      const dateObject = moment(value);
      const res = dateObject.format('dddd');
      if (this.getLanguage == 'en') {
        return res
      } if (this.getLanguage == 'de') {
        return this.$t(res)
      } if (this.getLanguage == 'sq') {
        return this.$t(res)
      } if (this.getLanguage == 'fr') {
        return this.$t(res)
      }
    },
    handleNumberOfEmployeesChange(val1, val2) {
      this.update_employee_number({
        routeMetricId: val1,
        newNumber: val2,
      })
    },
    changeComplete(val1, val2) {
      this.update_completed({
        routeMetricId: val1,
        completed: !val2,
      })
    },
    changeServiceTime(val1, val2) {
      this.update_service_time({
        routeMetricId: val1,
        serviceTime: !val2,
      })
    },
    changeRouteCreated(val1, val2) {
      this.update_route_created({
        routeMetricId: val1,
        routeCreated: !val2,
      })
    },

    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    async weeklyReport() {
      this.pressedWeek = true
      // this.buttonClicked = true
      // try {
      //   const temp1 = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DD')
      //   const temp2 = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DD')
      //   await this.route_metrics_reportingForStoreSecond({
      //     startDate: temp1,
      //     endDate: temp2,
      //   })
      // } catch (e) {
      //   console.log(e);
      // }
      const temp1 = moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT00:05:ss.SSS[Z]')
      const temp2 = moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT23:59:ss.SSS[Z]')
      try {
        // this.changeLoadingtoTrue(false);

        await this.route_metrics_reportingForStoreSecond({
          startDate: temp1,
          endDate: temp2,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    async daily() {
      this.time1 = moment().startOf('day').format('DD-MM-YYYY')
      this.time2 = moment().endOf('day').format('DD-MM-YYYY')
      this.pressedWeek = false
      this.buttonClicked = false

      // try {
      //   this.route_metrics_reportingForStore({
      //     startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      //     endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      //   })
      // } catch (e) {
      //   console.log(e);
      // }
      try {
        // this.changeLoadingtoTrue(false);

        await this.route_metrics_reportingForStore({
          startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    async week() {
      this.time1 = moment().startOf('isoWeek').isoWeekday(1).format('DD-MM-YYYY');
      this.time2 = moment().isoWeekday(6).endOf('day').format('DD-MM-YYYY');

      this.pressedWeek = true
      this.buttonClicked = true

      try {
        // this.changeLoadingtoTrue(false);

        await this.route_metrics_reportingForStoreSecond({
          startDate: moment().startOf('isoWeek').isoWeekday(1).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          endDate: moment()
            .isoWeekday(6)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },

  },
};
</script>

<style scoped lang="scss">

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}

.form-control{
  width: 80%;
  border: 1px solid #242F6E;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(191, 203, 217);;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0071AE;
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(117, 199, 145);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.search-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

span{
  color: black;
}
p{
  color: black;
}
// table td{
//   color: black;
// }

thead tr td{
  text-align: left;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
.button {
  margin-bottom: 15px !important;
  width: 195px;
}
.mat {
  display: flex;
  justify-content: space-between;
  align-content: center;
  &.active {
    background-color: #e8e8e8;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  span {
    margin-left: 10px;
    svg {
      font-size: 15px;
    }
  }
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

@media screen and (max-width: 1041px){
  .form-control{
  width: 100%;
}
}
@media screen and (max-width: 1823px) {
  .color-listing__wrapper2 {
    width: 200px;
    button {
      width: 90%;
    }
    .team_table {
      width: 150px !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .color-listing__wrapper {
    margin-left: 50px;
    width: 150px !important;
    .team_table {
      width: 150px !important;
    }
  }
  .color-listing__wrapper2 {
    margin-left: 10px;
    width: 200px !important;
    button {
      width: 150px;
      font-size: 13px;
      height: 36px;
    }
    .team_table {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 896px) {
  .color-listing__wrapper {
    margin-left: 0 !important;
    flex-basis: 49%;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex !important;
  width: 100%;
  margin: 0.25rem 0.25rem 0 !important;
}
</style>
